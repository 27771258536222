microflow.controller("tabsCtrl", [
  "$scope",
  function($scope) {
    var tab = "default";

    $scope.setTab = function(tabId) {
      tab = tabId;
    };
    $scope.isActivated = function(tabId) {
      return tab === tabId;
    };
  }
]);
